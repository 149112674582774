// Viewing styles
@import "vars";
// @import "normalize-scss";
// @include normalize();

/**
 * Tags
 */
// default styles
.layout-wrapper {
  @import "../common/reset_tags";
}

/**
 * Responsive & Layout
 */
@import "responsive";
@import "responsive_layouts";

/**
 * Parts
 */
@import "parts";
@import "parts_sys";

/**
 * Components
 */
@import "components";

.amsnext-inactivePreview {
  position: fixed;
  right: 16px;
  bottom: 16px;
  padding: 16px;
  border: solid 2px white;
  background: black;
  box-shadow: 0 0 8px rgba(0,0,0,.5);
  color: white;
  font-size: 16px;
  z-index: 99999;
}

/**
 * Libraries
 */
// Swiper
@import 'swiper/css/bundle';
.swiper-button-prev,
.swiper-button-next {
  color: gray;
}
.swiper-pagination-bullet,
.swiper-pagination-bullet-active {
  background: black;
}


// debug
pre.debug_dump {
  max-width: 100%;
  padding: 5px;
  background: #eee;
  white-space: pre-wrap;
  word-break: break-all;
}

