// vue image popuper
body.imagePopuper-shown {
  overflow: hidden;
}
.vue-imagePopuper {
  .imagePopuper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 8px;
    z-index: 1000;
  }

  .imagePopuper-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,.8);
  }

  .imagePopuper-close {
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 36px;
    color: gray;
    cursor: pointer;
    z-index: 1;
  }

  .swiper {
    height: calc(100vh - 16px);

    .swiper-slide {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-height: 100%;
      }
    }
  }
}
// vue modal windows
.vue-modalWindow-component
{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  overflow: visible;
  z-index: 1000;
}

.modalWindow-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.5);
}

.modalWindow {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 640px;
  max-height: calc(100vh - 20px * 2);
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 4px rgba(0,0,0,0.5);
  overflow: hidden;

  button {
    cursor: pointer;
  }

  // close
  .modalWindow-close {
    position: absolute;
    top: 0;
    right: 0;

    .modalWindow-close-button {
      background: none;
      border: none;
      outline: none;
      font-size: 20px;
    }
    z-index: 2;
  }

  // actions
  .modalWindow-actions {
    flex-grow: 0;
    flex-shrink: 0;
    height: 60px;
    padding: 10px;
    border-top: solid 1px #ccc;
    text-align: center;

    .modalWindow-action {
      display: inline-block;
      margin: 0 5px;
      padding: 5px 10px;
      background: #eee;
      border: solid 1px #ccc;
      border-radius: 4px;
      line-height: 20px;
      font-size: 16px;

      &.modalWindow-action-cancel {
        background: #ccc;
      }

      &:hover {
        background: #fff;
      }
    }
  }

  // body
  .modalWindow-body {
    display: flex;
    flex-direction: column;
    overflow: auto;
    margin: 20px 20px 0;

    &:last-child {
      margin-bottom: 20px;
    }

    .modalWindow-headline {
      flex-grow: 0;
      flex-shrink: 0;
      margin-bottom: 10px;
      line-height: 30px;
      font-size: 20px;
    }
  }
}

/** Modal window components
------------------------------ **/
// form confirm
.modalWindow-formConfirm {
  overflow: auto;

  table.modalWindow-formConfirm-table {
    width: 100%;
    border: none;
    border-collapse: collapse;

    th, td {
      padding: 10px;
      border-bottom: solid 1px #ccc;
    }

    th {
      width: 40%;
      font-weight: bold;
      text-align: right;
    }

    tr:last-of-type {
      th, td {
        border-bottom: none;
      }
    }
  }
}
