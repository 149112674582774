// system parts
.part-sys
{
}

// contents
.part-sys-contents,
.part-sys-content-relations
{
  @extend .part-contents;
}

// headline
.part-sys-contents-headline
{
  @extend .part-headline;
}

// pagination
.part-sys-contents-pagination
{
  ul, li
  {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li
  {
    display: inline-block;
  }

  .pages-current
  {
    font-weight: bold;
  }
}
