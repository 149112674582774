// parts
.part
{
  position: relative;
  word-break: break-all;
  z-index: 0;

  .part-body {
    z-index: 0;
  }

  // tags
  img
  {
    max-width: 100%;
    vertical-align: bottom;
  }

  video
  {
    max-width: 100%;
  }


  // common gallery (contents field)
  // Swiper
  .slide-items
  {
    max-width: 100%;

    &.slide-items-dotted {
      padding-bottom: 32px;
    }
  }

  .slide-item {
    .slide-image {
      text-align: center;
    }

    .slide-comment {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 8px;
      text-align: center;
    }
  }

  // Masonry
  .grid-items
  {
    &.grid-items-2 .grid-item
    {
      width: 50%;
    }
    &.grid-items-3 .grid-item
    {
      width: 33.33%;
    }
    &.grid-items-4 .grid-item
    {
      width: 25%;
    }
    &.grid-items-5 .grid-item
    {
      width: 20%;
    }
  }
}

// auth part
.part-auth
{
  .message
  {
    color: red;
  }
}

// columns part
.part-columns
{
  .columns
  {
    display: flex;
    justify-content: space-between;

    .columns-column
    {
      flex-grow: 1;
    }
    // default width
    &.columns-2 .columns-column {
      width: 50%;
    }
    &.columns-3 .columns-column {
      width: calc(100% / 3);
    }
    &.columns-4 .columns-column {
      width: 25%;
    }
    &.columns-5 .columns-column {
      width: 20%;
    }
  }
}

// contents
.part-contents
{
  .content-field-columns
  {
    display: flex;
    justify-content: space-between;

    .content-field-columns-column
    {
      flex-grow: 1;
    }
  }
}

// contents search
.part-contents-search
{
  .form-row {
    display: flex;
  }
}

// form
.part-form
{
  .form-must
  {
    span.must
    {
      color: red;
    }
  }
}

// headline
.part-headline
{
  span
  {
    &.headline-sub
    {
      font-size: 80%;
    }
  }
}

// image
.part-image
{
  &.part-image-center
  {
    text-align: center;
  }
  &.part-image-left
  {
    text-align: left;
  }
  &.part-image-right
  {
    text-align: right;
  }
}

// menu
.part-menu
{
  // .menu-list
  // {
  //   position: relative;

  //   .menu-child
  //   {
  //     display: none;
  //   }

  //   li:hover .menu-child
  //   {
  //     display: block;
  //   }
  // }
}

// editor reset
.part {
  .form-beforeText,
  .form-afterText,
  .text,
  .content-field-text,
  .content-field-value {
    ul {
      padding-left: 40px;
      list-style: disc;
    }
    ol {
      padding-left: 40px;
      list-style: decimal;
    }
    th, td {
      border: solid 1px;
    }
  }
}



