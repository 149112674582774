// break points
$breakpoint-sp: 320px;
$breakpoint-tablet: 768px;
$breakpoint-pc: 1024px;
$breakpoint-pc-hd: 1280px;
$breakpoint-pc-fhd: 1920px;

@mixin mediaquery-sp() {
  @media screen and ( max-width:$breakpoint-tablet) {
    @content;
  }
}
@mixin mediaquery-tablet() {
  @media screen and (min-width:$breakpoint-tablet) and ( max-width:$breakpoint-pc) {
    @content;
  }
}
@mixin mediaquery-pc() {
  @media screen and (min-width:$breakpoint-pc) {
    @content;
  }
}
@mixin mediaquery-pc-hd() {
  @media screen and (min-width:$breakpoint-pc-hd) {
    @content;
  }
}
@mixin mediaquery-pc-fhd() {
  @media screen and (min-width:$breakpoint-pc-fhd) {
    @content;
  }
}
