h1, h2, h3, h4, h5, h6 {
  margin: 2em 0 1em;
  font-weight: bold;
}
h1 {
  font-size: 24px;
}
h2 {
  font-size: 20px;
}
h3 {
  font-size: 18px;
}
h4 {
  font-size: 16px;
}
h5 {
  font-size: 14px;
}
h6 {
  font-size: 12px;
}

a {
  color: blue;
}

b, strong {
  font-weight: bold;
}

i, em {
  font-style: italic;
}