/** SP
------------------------------ **/
@include mediaquery-sp {
}

/** Tablet
------------------------------ **/
@include mediaquery-tablet {
}

/** PC
------------------------------ **/
@include mediaquery-pc {
  // wrapper
  .layout-wrapper
  {
    max-width: 1140px;
    margin: auto;
  }
  // columns
  .layout-columns
  {
    .layout-columns-body
    {
      display: flex;
    }

    // main block
    .block-main
    {
      flex-grow: 0;
      flex-shrink: 0;

      &:only-child
      {
        width: 100%;
      }
    }

    &.layout-columns-1 .block-main
    {
      width: 100%;
    }
    &.layout-columns-2 .block-main
    {
      width: 70%;
    }
    &.layout-columns-3 .block-main
    {
      width: 60%;
    }

    // side block
    .block-side
    {
      flex-grow: 1;
      flex-shrink: 1;
    }
    // sub block
    .block-sub
    {
      flex-grow: 1;
      flex-shrink: 1;
    }
  }
}
