/** SP
------------------------------ **/
@include mediaquery-sp {
	.media-not-pc,
	.media-not-tablet,
	.media-only-sp
	{
		display: block !important;
	}
	.media-only-pc,
	.media-only-tablet,
	.media-not-sp
	{
		display: none !important;
	}
}

/** Tablet
------------------------------ **/
@include mediaquery-tablet {
	.media-not-pc,
	.media-only-tablet,
	.media-not-sp
	{
		display: block !important;
	}
	.media-only-pc,
	.media-not-tablet,
	.media-only-sp
	{
		display: none !important;
	}
}

/** PC
------------------------------ **/
@include mediaquery-pc {
  .media-only-pc,
  .media-not-tablet,
  .media-not-sp
  {
    display: block !important;
  }
  .media-not-pc,
  .media-only-tablet,
  .media-only-sp
  {
    display: none !important;
  }
}
